<template>
  <div class="wrap" id="pros">
    <div class="project">
      <div class="tabs">
        <div class="tabNav">
          <div class="item" :class="{ cur: cur == 1 }" @click="cur = 1">
            ALL
          </div>
          <div class="item" :class="{ cur: cur == 2 }" @click="cur = 2">
            Corporate Promotional video
          </div>
          <div class="item" :class="{ cur: cur == 3 }" @click="cur = 3">
            Product promotion video
          </div>
          <div class="item" :class="{ cur: cur == 4 }" @click="cur = 4">
            3 d video
          </div>
          <div class="item" :class="{ cur: cur == 5 }" @click="cur = 5">
            MG animation
          </div>
        </div>
        <div class="tabContent spec" v-if="cur == 1">
          <div class="pics" @click="showVideo('辉瑞苏州工厂质量视频——儿童篇','https://kangfei123.oss-cn-beijing.aliyuncs.com/%E4%BC%81%E4%B8%9A%E5%AE%A3%E4%BC%A0%E7%89%87/1%E8%BE%89%E7%91%9E%E8%8B%8F%E5%B7%9E%E5%B7%A5%E5%8E%82%E8%B4%A8%E9%87%8F%E8%A7%86%E9%A2%91/%E8%BE%89%E7%91%9E%E8%8B%8F%E5%B7%9E%E5%B7%A5%E5%8E%82%E8%B4%A8%E9%87%8F%E8%A7%86%E9%A2%91%E2%80%94%E2%80%94%E5%84%BF%E7%AB%A5%E7%AF%87.mp4')">
            <img :src="`img/1-1huiruiertong.png`" alt="" />
            <div class="intro">辉瑞苏州工厂质量视频——儿童篇</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover1-23.png`" alt="">
              <div class="tit">辉瑞苏州工厂质量视频——儿童篇</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('辉瑞苏州工厂质量视频——钙尔奇篇','https://kangfei123.oss-cn-beijing.aliyuncs.com/%E4%BC%81%E4%B8%9A%E5%AE%A3%E4%BC%A0%E7%89%87/1%E8%BE%89%E7%91%9E%E8%8B%8F%E5%B7%9E%E5%B7%A5%E5%8E%82%E8%B4%A8%E9%87%8F%E8%A7%86%E9%A2%91/%E8%BE%89%E7%91%9E%E8%8B%8F%E5%B7%9E%E5%B7%A5%E5%8E%82%E8%B4%A8%E9%87%8F%E8%A7%86%E9%A2%91%E2%80%94%E2%80%94%E9%92%99%E5%B0%94%E5%A5%87%E7%AF%87.mp4')">
            <img :src="`img/1-2huiruikaierqi.png`" alt="" />
            <div class="intro">辉瑞苏州工厂质量视频——钙尔奇篇</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover1-24.png`" alt="">
              <div class="tit">辉瑞苏州工厂质量视频——钙尔奇篇</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('辉瑞苏州工厂质量视频——善存篇','https://kangfei123.oss-cn-beijing.aliyuncs.com/%E4%BC%81%E4%B8%9A%E5%AE%A3%E4%BC%A0%E7%89%87/1%E8%BE%89%E7%91%9E%E8%8B%8F%E5%B7%9E%E5%B7%A5%E5%8E%82%E8%B4%A8%E9%87%8F%E8%A7%86%E9%A2%91/%E8%BE%89%E7%91%9E%E8%8B%8F%E5%B7%9E%E5%B7%A5%E5%8E%82%E8%B4%A8%E9%87%8F%E8%A7%86%E9%A2%91%E2%80%94%E2%80%94%E5%96%84%E5%AD%98%E7%AF%87.mp4')">
            <img :src="`img/1-3huiruishancun.png`" alt="" />
            <div class="intro">辉瑞苏州工厂质量视频——善存篇</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover1-25.png`" alt="">
              <div class="tit">辉瑞苏州工厂质量视频——善存篇</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('《爱的捉迷藏》——Director\'s Cut','https://kangfei123.oss-cn-beijing.aliyuncs.com/%E4%BC%81%E4%B8%9A%E5%AE%A3%E4%BC%A0%E7%89%87/2%E5%BF%97%E9%82%A6%E7%88%B1%E5%AE%B6%E8%A1%8C%E5%8A%A8/%E3%80%8A%E7%88%B1%E7%9A%84%E6%8D%89%E8%BF%B7%E8%97%8F%E3%80%8B%E2%80%94%E2%80%94Director%27s%20Cut.mp4')">
            <img :src="`img/2-1zhibangzhuomicang.png`" alt="" />
            <div class="intro">《爱的捉迷藏》——Director's Cut</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover1-1.png`" alt="">
              <div class="tit">《爱的捉迷藏》——Director's Cut</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('《第一口美味特权》','https://kangfei123.oss-cn-beijing.aliyuncs.com/%E4%BC%81%E4%B8%9A%E5%AE%A3%E4%BC%A0%E7%89%87/2%E5%BF%97%E9%82%A6%E7%88%B1%E5%AE%B6%E8%A1%8C%E5%8A%A8/%E3%80%8A%E7%AC%AC%E4%B8%80%E5%8F%A3%E7%BE%8E%E5%91%B3%E7%89%B9%E6%9D%83%E3%80%8B%E2%80%94%E2%80%94Director%27s%20Cut.mp4')">
            <img :src="`img/2-2zhibangdiyikou.png`" alt="" />
            <div class="intro">《第一口美味特权》</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover1-2.png`" alt="">
              <div class="tit">《第一口美味特权》</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('《我的治愈系衣柜》','https://kangfei123.oss-cn-beijing.aliyuncs.com/%E4%BC%81%E4%B8%9A%E5%AE%A3%E4%BC%A0%E7%89%87/2%E5%BF%97%E9%82%A6%E7%88%B1%E5%AE%B6%E8%A1%8C%E5%8A%A8/%E3%80%8A%E6%88%91%E7%9A%84%E6%B2%BB%E6%84%88%E7%B3%BB%E8%A1%A3%E6%9F%9C%E3%80%8B%E2%80%94%E2%80%94Director%27s%20Cut.mp4')">
            <img :src="`img/2-3zhibangzhiyuxi.png`" alt="" />
            <div class="intro">《我的治愈系衣柜》</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover1-3.png`" alt="">
              <div class="tit">《我的治愈系衣柜》</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('飞依诺杏聆荟微电影','https://kangfei123.oss-cn-beijing.aliyuncs.com/%E4%BC%81%E4%B8%9A%E5%AE%A3%E4%BC%A0%E7%89%87/3%E9%A3%9E%E4%BE%9D%E8%AF%BA%E6%9D%8F%E8%81%86%E8%8D%9F%E5%BE%AE%E7%94%B5%E5%BD%B1.mp4')">
            <img :src="`img/3-vinnoweidianying.png`" alt="" />
            <div class="intro">飞依诺杏聆荟微电影</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover1-4.png`" alt="">
              <div class="tit">飞依诺杏聆荟微电影</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('住逻辑英文版宣传片','https://kangfei123.oss-cn-beijing.aliyuncs.com/%E4%BC%81%E4%B8%9A%E5%AE%A3%E4%BC%A0%E7%89%87/4%E4%BD%8F%E9%80%BB%E8%BE%91%E8%8B%B1%E6%96%87%E7%89%88%E5%AE%A3%E4%BC%A0%E7%89%87.mp4')">
            <img :src="`img/4-zhuluojiyingwenban.png`" alt="" />
            <div class="intro">住逻辑英文版宣传片</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover1-5.png`" alt="">
              <div class="tit">住逻辑英文版宣传片</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('飞依诺《超声英雄》微电影','https://kangfei123.oss-cn-beijing.aliyuncs.com/%E4%BC%81%E4%B8%9A%E5%AE%A3%E4%BC%A0%E7%89%87/5%E9%A3%9E%E4%BE%9D%E8%AF%BA%E3%80%8A%E8%B6%85%E5%A3%B0%E8%8B%B1%E9%9B%84%E3%80%8B%E5%BE%AE%E7%94%B5%E5%BD%B1.mp4')">
            <img :src="`img/5-chaoshengyingxiong.png`" alt="" />
            <div class="intro">飞依诺《超声英雄》微电影</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover1-6.png`" alt="">
              <div class="tit">飞依诺《超声英雄》微电影</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('艾信opening video','https://kangfei123.oss-cn-beijing.aliyuncs.com/%E4%BC%81%E4%B8%9A%E5%AE%A3%E4%BC%A0%E7%89%87/6%E8%89%BE%E4%BF%A1opening%20video.mp4')">
            <img :src="`img/6-aixinopenvideo.png`" alt="" />
            <div class="intro">艾信opening video</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover1-7.png`" alt="">
              <div class="tit">艾信opening video</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('英途康背景opening video','https://kangfei123.oss-cn-beijing.aliyuncs.com/%E4%BC%81%E4%B8%9A%E5%AE%A3%E4%BC%A0%E7%89%87/7%E8%8B%B1%E9%80%94%E5%BA%B7%E8%83%8C%E6%99%AFopening%20video.mp4')">
            <img :src="`img/7-yingtukangopenvideo.png`" alt="" />
            <div class="intro">英途康背景opening video</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover1-8.png`" alt="">
              <div class="tit">英途康背景opening video</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('飞依诺七周年国际版宣传片','https://kangfei123.oss-cn-beijing.aliyuncs.com/%E4%BC%81%E4%B8%9A%E5%AE%A3%E4%BC%A0%E7%89%87/8%E9%A3%9E%E4%BE%9D%E8%AF%BA%E4%B8%83%E5%91%A8%E5%B9%B4%E5%9B%BD%E9%99%85%E7%89%88%E5%AE%A3%E4%BC%A0%E7%89%87.mp4')">
            <img :src="`img/8-vinno7zhounian.png`" alt="" />
            <div class="intro">飞依诺七周年国际版宣传片</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover1-9.png`" alt="">
              <div class="tit">飞依诺七周年国际版宣传片</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('独墅联盟','https://kangfei123.oss-cn-beijing.aliyuncs.com/%E4%BC%81%E4%B8%9A%E5%AE%A3%E4%BC%A0%E7%89%87/9%E7%8B%AC%E5%A2%85%E8%81%94%E7%9B%9Fopening%20video.mp4')">
            <img :src="`img/9-dushulianmeng.png`" alt="" />
            <div class="intro">独墅联盟</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover1-10.png`" alt="">
              <div class="tit">独墅联盟</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('海斯凯尔宣传片','https://kangfei123.oss-cn-beijing.aliyuncs.com/%E4%BC%81%E4%B8%9A%E5%AE%A3%E4%BC%A0%E7%89%87/9%E6%B5%B7%E6%96%AF%E5%87%AF%E5%B0%94%E5%AE%A3%E4%BC%A0%E7%89%87.mp4')">
            <img :src="`img/10-haisikaierxuanchuanpian.png`" alt="" />
            <div class="intro">海斯凯尔宣传片</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover1-11.png`" alt="">
              <div class="tit">海斯凯尔宣传片</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('飞依诺银杏的故事opening video','https://kangfei123.oss-cn-beijing.aliyuncs.com/%E4%BC%81%E4%B8%9A%E5%AE%A3%E4%BC%A0%E7%89%87/10%E9%A3%9E%E4%BE%9D%E8%AF%BA%E9%93%B6%E6%9D%8F%E7%9A%84%E6%95%85%E4%BA%8Bopening%20video.mp4')">
            <img :src="`img/11-yinxingushi.png`" alt="" />
            <div class="intro">飞依诺银杏的故事opening video</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover1-12.png`" alt="">
              <div class="tit">飞依诺银杏的故事opening video</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('微清访谈宣传片','https://kangfei123.oss-cn-beijing.aliyuncs.com/%E4%BC%81%E4%B8%9A%E5%AE%A3%E4%BC%A0%E7%89%87/11%E5%BE%AE%E6%B8%85%E8%AE%BF%E8%B0%88%E5%AE%A3%E4%BC%A0%E7%89%87.mp4')">
            <img :src="`img/12-weiqingfangtan.png`" alt="" />
            <div class="intro">微清访谈宣传片</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover1-13.png`" alt="">
              <div class="tit">微清访谈宣传片</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('艾琳格瑞宣传片 英文版','https://kangfei123.oss-cn-beijing.aliyuncs.com/%E4%BC%81%E4%B8%9A%E5%AE%A3%E4%BC%A0%E7%89%87/12%E8%89%BE%E7%90%B3%E6%A0%BC%E7%91%9E%E5%AE%A3%E4%BC%A0%E7%89%87%20%E8%8B%B1%E6%96%87%E7%89%88.mov')">
            <img :src="`img/13-ailingerui.png`" alt="" />
            <div class="intro">艾琳格瑞宣传片 英文版</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover1-14.png`" alt="">
              <div class="tit">艾琳格瑞宣传片 英文版</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('独墅联盟宣传片','https://kangfei123.oss-cn-beijing.aliyuncs.com/%E4%BC%81%E4%B8%9A%E5%AE%A3%E4%BC%A0%E7%89%87/12%E7%8B%AC%E5%A2%85%E8%81%94%E7%9B%9F%E5%AE%A3%E4%BC%A0%E7%89%87.mp4')">
            <img :src="`img/14-dushulianmengxuanchuanpian.png`" alt="" />
            <div class="intro">独墅联盟宣传片</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover1-15.png`" alt="">
              <div class="tit">独墅联盟宣传片</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('宜洁微电影','https://kangfei123.oss-cn-beijing.aliyuncs.com/%E4%BC%81%E4%B8%9A%E5%AE%A3%E4%BC%A0%E7%89%87/12%E5%AE%9C%E6%B4%81%E5%BE%AE%E7%94%B5%E5%BD%B1.mp4')">
            <img :src="`img/15-yijieweidianying.png`" alt="" />
            <div class="intro">宜洁微电影</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover1-16.png`" alt="">
              <div class="tit">宜洁微电影</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('TaTME影片','https://kangfei123.oss-cn-beijing.aliyuncs.com/%E4%BC%81%E4%B8%9A%E5%AE%A3%E4%BC%A0%E7%89%87/13TaTME%E5%BD%B1%E7%89%87.mp4')">
            <img :src="`img/16-tatmeyingpian.png`" alt="" />
            <div class="intro">TaTME影片</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover1-17.png`" alt="">
              <div class="tit">TaTME影片</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('众享比特宣传片','https://kangfei123.oss-cn-beijing.aliyuncs.com/%E4%BC%81%E4%B8%9A%E5%AE%A3%E4%BC%A0%E7%89%87/14%E4%BC%97%E4%BA%AB%E6%AF%94%E7%89%B9%E5%AE%A3%E4%BC%A0%E7%89%87.mp4')">
            <img :src="`img/17-zhongxiangbitexuanchuanpian.png`" alt="" />
            <div class="intro">众享比特宣传片</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover1-18.png`" alt="">
              <div class="tit">众享比特宣传片</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('合肥数字经济产业试验区宣传片','https://kangfei123.oss-cn-beijing.aliyuncs.com/%E4%BC%81%E4%B8%9A%E5%AE%A3%E4%BC%A0%E7%89%87/16%E5%90%88%E8%82%A5%E6%95%B0%E5%AD%97%E7%BB%8F%E6%B5%8E%E4%BA%A7%E4%B8%9A%E8%AF%95%E9%AA%8C%E5%8C%BA%E5%AE%A3%E4%BC%A0%E7%89%87.mp4')">
            <img :src="`img/19-hefeishuzijingji.png`" alt="" />
            <div class="intro">合肥数字经济产业试验区宣传片</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover1-20.png`" alt="">
              <div class="tit">合肥数字经济产业试验区宣传片</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('网易数字经济宣传片','https://kangfei123.oss-cn-beijing.aliyuncs.com/%E4%BC%81%E4%B8%9A%E5%AE%A3%E4%BC%A0%E7%89%87/17%E7%BD%91%E6%98%93%E6%95%B0%E5%AD%97%E7%BB%8F%E6%B5%8E%E5%AE%A3%E4%BC%A0%E7%89%87.mp4')">
            <img :src="`img/20-wangyishuzijingji.png`" alt="" />
            <div class="intro">网易数字经济宣传片</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover1-21.png`" alt="">
              <div class="tit">网易数字经济宣传片</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('易活生物海外版本宣传片','https://kangfei123.oss-cn-beijing.aliyuncs.com/%E4%BC%81%E4%B8%9A%E5%AE%A3%E4%BC%A0%E7%89%87/18%E6%98%93%E6%B4%BB%E7%94%9F%E7%89%A9%E6%B5%B7%E5%A4%96%E7%89%88%E6%9C%AC%E5%AE%A3%E4%BC%A0%E7%89%87.mp4')">
            <img :src="`img/21-yihuoshengwu.png`" alt="" />
            <div class="intro">易活生物海外版本宣传片</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover1-22.png`" alt="">
              <div class="tit">易活生物海外版本宣传片</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('飞依诺G86产品影片','https://kangfei123.oss-cn-beijing.aliyuncs.com/1%E9%A3%9E%E4%BE%9D%E8%AF%BAG86%E4%BA%A7%E5%93%81%E5%BD%B1%E7%89%87.mp4')">
            <img :src="`img/1-g86.png`" alt="" />
            <div class="intro">飞依诺G86产品影片</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover1.png`" alt="">
              <div class="tit">飞依诺G86产品影片</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('IntoCare电动吻合器产品影片','https://kangfei123.oss-cn-beijing.aliyuncs.com/2%E9%A3%9E%E4%BE%9D%E8%AF%BAM86%E4%B8%BB%E9%A2%98%E5%BD%B1%E7%89%87.mp4')">
            <img :src="`img/2-m86.png`" alt="" />
            <div class="intro">飞依诺M86主题影片</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover2.png`" alt="">
              <div class="tit">飞依诺M86主题影片</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('飞依诺M80产品影片','https://kangfei123.oss-cn-beijing.aliyuncs.com/3%E9%A3%9E%E4%BE%9D%E8%AF%BAM80%E4%BA%A7%E5%93%81%E5%BD%B1%E7%89%87.mp4')">
            <img :src="`img/3-m80.png`" alt="" />
            <div class="intro">飞依诺M80产品影片</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover3.png`" alt="">
              <div class="tit">飞依诺M80产品影片</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('VINNO - G55产品影片','https://kangfei123.oss-cn-beijing.aliyuncs.com/4VINNO%20-%20G55%E4%BA%A7%E5%93%81%E5%BD%B1%E7%89%87.mp4')">
            <img :src="`img/4-g55.png`" alt="" />
            <div class="intro">VINNO - G55产品影片</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover4.png`" alt="">
              <div class="tit">vVINNO - G55产品影片</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('飞依诺Q系列影片','https://kangfei123.oss-cn-beijing.aliyuncs.com/5%E9%A3%9E%E4%BE%9D%E8%AF%BAQ%E7%B3%BB%E5%88%97%E5%BD%B1%E7%89%87.mp4')">
            <img :src="`img/5-q.png`" alt="" />
            <div class="intro">飞依诺Q系列影片</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover5.png`" alt="">
              <div class="tit">飞依诺Q系列影片</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('英途康ICS三维影片','https://kangfei123.oss-cn-beijing.aliyuncs.com/6%E8%8B%B1%E9%80%94%E5%BA%B7ICS%E4%B8%89%E7%BB%B4%E5%BD%B1%E7%89%87.mp4')">
            <img :src="`img/6-ics.png`" alt="" />
            <div class="intro">英途康ICS三维影片</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover6.png`" alt="">
              <div class="tit">英途康ICS三维影片</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('小米金属行李箱产品影片','https://kangfei123.oss-cn-beijing.aliyuncs.com/7%E5%B0%8F%E7%B1%B3%E9%87%91%E5%B1%9E%E8%A1%8C%E6%9D%8E%E7%AE%B1%E4%BA%A7%E5%93%81%E5%BD%B1%E7%89%87.mp4')">
            <img :src="`img/7-mimetal.png`" alt="" />
            <div class="intro">小米金属行李箱产品影片</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover7.png`" alt="">
              <div class="tit">小米金属行李箱产品影片</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('海斯凯尔 - iLivTouch宣传片','https://kangfei123.oss-cn-beijing.aliyuncs.com/8%E6%B5%B7%E6%96%AF%E5%87%AF%E5%B0%94%20-%20iLivTouch%E5%AE%A3%E4%BC%A0%E7%89%87.mp4')">
            <img :src="`img/8-ilivtouch.png`" alt="" />
            <div class="intro">海斯凯尔 - iLivTouch宣传片</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover8.png`" alt="">
              <div class="tit">海斯凯尔 - iLivTouch宣传片</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('宜洁彩色餐具三维影片','https://kangfei123.oss-cn-beijing.aliyuncs.com/9%E5%AE%9C%E6%B4%81%E5%BD%A9%E8%89%B2%E9%A4%90%E5%85%B7%E4%B8%89%E7%BB%B4%E5%BD%B1%E7%89%87.mp4')">
            <img :src="`img/9-yijiecaisecanju.png`" alt="" />
            <div class="intro">宜洁彩色餐具三维影片</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover9.png`" alt="">
              <div class="tit">宜洁彩色餐具三维影片</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('小米PC行李箱产品影片','https://kangfei123.oss-cn-beijing.aliyuncs.com/10%E5%B0%8F%E7%B1%B3PC%E8%A1%8C%E6%9D%8E%E7%AE%B1%E4%BA%A7%E5%93%81%E5%BD%B1%E7%89%87.mp4')">
            <img :src="`img/10-mipc.png`" alt="" />
            <div class="intro">小米PC行李箱产品影片</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover10.png`" alt="">
              <div class="tit">小米PC行李箱产品影片</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('飞依诺产品短片','https://kangfei123.oss-cn-beijing.aliyuncs.com/11%E9%A3%9E%E4%BE%9D%E8%AF%BA%E4%BA%A7%E5%93%81%E7%9F%AD%E7%89%87.mp4')">
            <img :src="`img/11-vinno.png`" alt="" />
            <div class="intro">飞依诺产品短片</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover11.png`" alt="">
              <div class="tit">飞依诺产品短片</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('飞依诺UI影片','https://kangfei123.oss-cn-beijing.aliyuncs.com/12%E9%A3%9E%E4%BE%9D%E8%AF%BAUI%E5%BD%B1%E7%89%87.mp4')">
            <img :src="`img/12-vinnoui.png`" alt="" />
            <div class="intro">飞依诺UI影片</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover12.png`" alt="">
              <div class="tit">飞依诺UI影片</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('宜洁木纹餐具三维影片','https://kangfei123.oss-cn-beijing.aliyuncs.com/12%E5%AE%9C%E6%B4%81%E6%9C%A8%E7%BA%B9%E9%A4%90%E5%85%B7%E4%B8%89%E7%BB%B4%E5%BD%B1%E7%89%87.mp4')">
            <img :src="`img/13-yijiemuwencanju.png`" alt="" />
            <div class="intro">宜洁木纹餐具三维影片</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover13.png`" alt="">
              <div class="tit">宜洁木纹餐具三维影片</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('来也科技智能机器人宣传片','https://kangfei123.oss-cn-beijing.aliyuncs.com/13%E6%9D%A5%E4%B9%9F%E7%A7%91%E6%8A%80%E6%99%BA%E8%83%BD%E6%9C%BA%E5%99%A8%E4%BA%BA%E5%AE%A3%E4%BC%A0%E7%89%87.mp4')">
            <img :src="`img/14-laiyekeji.png`" alt="" />
            <div class="intro">来也科技智能机器人宣传片</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover14.png`" alt="">
              <div class="tit">来也科技智能机器人宣传片</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('宜洁扑克牌产品影片','https://kangfei123.oss-cn-beijing.aliyuncs.com/13%E5%AE%9C%E6%B4%81%E6%89%91%E5%85%8B%E7%89%8C%E4%BA%A7%E5%93%81%E5%BD%B1%E7%89%87.mp4')">
            <img :src="`img/15-yijiepukepai.png`" alt="" />
            <div class="intro">宜洁扑克牌产品影片</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover15.png`" alt="">
              <div class="tit">宜洁扑克牌产品影片</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('爱生气气泡酒','https://kangfei123.oss-cn-beijing.aliyuncs.com/15%E7%88%B1%E7%94%9F%E6%B0%94%E6%B0%94%E6%B3%A1%E9%85%92.mp4')">
            <img :src="`img/17-aishengqi.png`" alt="" />
            <div class="intro">爱生气气泡酒</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover17.png`" alt="">
              <div class="tit">爱生气气泡酒</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('柏兆记产品短视频','https://kangfei123.oss-cn-beijing.aliyuncs.com/16%E6%9F%8F%E5%85%86%E8%AE%B0%E4%BA%A7%E5%93%81%E7%9F%AD%E8%A7%86%E9%A2%91.mp4')">
            <img :src="`img/18-bozhaoji.png`" alt="" />
            <div class="intro">柏兆记产品短视频</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover18.png`" alt="">
              <div class="tit">柏兆记产品短视频</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('宜洁雨伞产品影片','https://kangfei123.oss-cn-beijing.aliyuncs.com/16%E5%AE%9C%E6%B4%81%E9%9B%A8%E4%BC%9E%E4%BA%A7%E5%93%81%E5%BD%B1%E7%89%87.mp4')">
            <img :src="`img/19-yijieyusan.png`" alt="" />
            <div class="intro">宜洁雨伞产品影片</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover19.png`" alt="">
              <div class="tit">宜洁雨伞产品影片</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('老乡鸡云南辣椒影片','https://kangfei123.oss-cn-beijing.aliyuncs.com/17%E8%80%81%E4%B9%A1%E9%B8%A1%E4%BA%91%E5%8D%97%E8%BE%A3%E6%A4%92%E5%BD%B1%E7%89%87.mp4')">
            <img :src="`img/20-laoxiangji.png`" alt="" />
            <div class="intro">老乡鸡云南辣椒影片</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover20.png`" alt="">
              <div class="tit">老乡鸡云南辣椒影片</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('荷乐事饮品宣传片','https://kangfei123.oss-cn-beijing.aliyuncs.com/18%E8%8D%B7%E4%B9%90%E4%BA%8B%E9%A5%AE%E5%93%81%E5%AE%A3%E4%BC%A0%E7%89%87.mp4')">
            <img :src="`img/21-heleshiyinpin.png`" alt="" />
            <div class="intro">荷乐事饮品宣传片</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover21.png`" alt="">
              <div class="tit">荷乐事饮品宣传片</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('飞依诺杏聆荟远程功能视频教学影片','https://kangfei123.oss-cn-beijing.aliyuncs.com/19%E9%A3%9E%E4%BE%9D%E8%AF%BA%E6%9D%8F%E8%81%86%E8%8D%9F%E8%BF%9C%E7%A8%8B%E5%8A%9F%E8%83%BD%E8%A7%86%E9%A2%91%E6%95%99%E5%AD%A6%E5%BD%B1%E7%89%87.mp4')">
            <img :src="`img/22-vinnoxinlinhui.png`" alt="" />
            <div class="intro">飞依诺杏聆荟远程功能视频教学影片</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover22.png`" alt="">
              <div class="tit">飞依诺杏聆荟远程功能视频教学影片</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('英途康电子说明书','https://kangfei123.oss-cn-beijing.aliyuncs.com/20%E8%8B%B1%E9%80%94%E5%BA%B7%E7%94%B5%E5%AD%90%E8%AF%B4%E6%98%8E%E4%B9%A6.mp4')">
            <img :src="`img/23-yingtukang.png`" alt="" />
            <div class="intro">英途康电子说明书</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover23.png`" alt="">
              <div class="tit">英途康电子说明书</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('日式松茸饭影片','https://kangfei123.oss-cn-beijing.aliyuncs.com/21%E6%97%A5%E5%BC%8F%E6%9D%BE%E8%8C%B8%E9%A5%AD%E5%BD%B1%E7%89%87.mp4')">
            <img :src="`img/24-rishisongrongfan.png`" alt="" />
            <div class="intro">日式松茸饭影片</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover24.png`" alt="">
              <div class="tit">日式松茸饭影片</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('英途康ICS组织呈现应用视频1','https://kangfei123.oss-cn-beijing.aliyuncs.com/6%E8%8B%B1%E9%80%94%E5%BA%B7ICS%E4%B8%89%E7%BB%B4%E5%BD%B1%E7%89%87.mp4')">
            <img :src="`img/25-yingtukangics1.png`" alt="" />
            <div class="intro">英途康ICS组织呈现应用视频1</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover25.png`" alt="">
              <div class="tit">英途康ICS组织呈现应用视频1</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('英途康ICS组织呈现应用视频2','')">
            <img :src="`img/26-yingtukangics2.png`" alt="" />
            <div class="intro">英途康ICS组织呈现应用视频2</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover26.png`" alt="">
              <div class="tit">英途康ICS组织呈现应用视频2</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('英途康ICS组织呈现应用视频3','')">
            <img :src="`img/27-yingtukangics3.png`" alt="" />
            <div class="intro">英途康ICS组织呈现应用视频3</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover27.png`" alt="">
              <div class="tit">英途康ICS组织呈现应用视频3</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('车友援mg','https://kangfei123.oss-cn-beijing.aliyuncs.com/MG%E5%8A%A8%E7%94%BB/1%E8%BD%A6%E5%8F%8B%E6%8F%B4MG.mp4')">
            <img :src="`img/1-cheyouyuanmg.png`" alt="" />
            <div class="intro">车友援mg</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hovermg1.png`" alt="">
              <div class="tit">车友援mg</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('安徽旅游日logo解读','https://kangfei123.oss-cn-beijing.aliyuncs.com/MG%E5%8A%A8%E7%94%BB/2%E5%AE%89%E5%BE%BD%E6%97%85%E6%B8%B8%E6%97%A5logo%E8%A7%A3%E8%AF%BB.mp4')">
            <img :src="`img/2-anhuilvyouri.png`" alt="" />
            <div class="intro">安徽旅游日logo解读</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hovermg2.png`" alt="">
              <div class="tit">安徽旅游日logo解读</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('艾琳格瑞','https://kangfei123.oss-cn-beijing.aliyuncs.com/MG%E5%8A%A8%E7%94%BB/3%E8%89%BE%E7%90%B3%E6%A0%BC%E7%91%9E.mp4')">
            <img :src="`img/3-ailingerui.png`" alt="" />
            <div class="intro">艾琳格瑞</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hovermg3.png`" alt="">
              <div class="tit">艾琳格瑞</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>





          <div class="pages">
            <div class="page">
              <template v-for="(item, index) in count">
                <span
                  class="pageNo"
                  :class="{ check: pageIndex == index }"
                  @click="pageIndex = index"
                  >{{ item }}</span
                >
              </template>
            </div>
            <div class="nextPage" @click="nextPage">
              <img :src="`img/nextPage.png`" alt="" />
            </div>
          </div>
        </div>
        <div class="tabContent" v-if="cur == 2">
          <div class="pics" @click="showVideo('辉瑞苏州工厂质量视频——儿童篇','https://kangfei123.oss-cn-beijing.aliyuncs.com/%E4%BC%81%E4%B8%9A%E5%AE%A3%E4%BC%A0%E7%89%87/1%E8%BE%89%E7%91%9E%E8%8B%8F%E5%B7%9E%E5%B7%A5%E5%8E%82%E8%B4%A8%E9%87%8F%E8%A7%86%E9%A2%91/%E8%BE%89%E7%91%9E%E8%8B%8F%E5%B7%9E%E5%B7%A5%E5%8E%82%E8%B4%A8%E9%87%8F%E8%A7%86%E9%A2%91%E2%80%94%E2%80%94%E5%84%BF%E7%AB%A5%E7%AF%87.mp4')">
            <img :src="`img/1-1huiruiertong.png`" alt="" />
            <div class="intro">辉瑞苏州工厂质量视频——儿童篇</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover1-23.png`" alt="">
              <div class="tit">辉瑞苏州工厂质量视频——儿童篇</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('辉瑞苏州工厂质量视频——钙尔奇篇','https://kangfei123.oss-cn-beijing.aliyuncs.com/%E4%BC%81%E4%B8%9A%E5%AE%A3%E4%BC%A0%E7%89%87/1%E8%BE%89%E7%91%9E%E8%8B%8F%E5%B7%9E%E5%B7%A5%E5%8E%82%E8%B4%A8%E9%87%8F%E8%A7%86%E9%A2%91/%E8%BE%89%E7%91%9E%E8%8B%8F%E5%B7%9E%E5%B7%A5%E5%8E%82%E8%B4%A8%E9%87%8F%E8%A7%86%E9%A2%91%E2%80%94%E2%80%94%E9%92%99%E5%B0%94%E5%A5%87%E7%AF%87.mp4')">
            <img :src="`img/1-2huiruikaierqi.png`" alt="" />
            <div class="intro">辉瑞苏州工厂质量视频——钙尔奇篇</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover1-24.png`" alt="">
              <div class="tit">辉瑞苏州工厂质量视频——钙尔奇篇</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('辉瑞苏州工厂质量视频——善存篇','https://kangfei123.oss-cn-beijing.aliyuncs.com/%E4%BC%81%E4%B8%9A%E5%AE%A3%E4%BC%A0%E7%89%87/1%E8%BE%89%E7%91%9E%E8%8B%8F%E5%B7%9E%E5%B7%A5%E5%8E%82%E8%B4%A8%E9%87%8F%E8%A7%86%E9%A2%91/%E8%BE%89%E7%91%9E%E8%8B%8F%E5%B7%9E%E5%B7%A5%E5%8E%82%E8%B4%A8%E9%87%8F%E8%A7%86%E9%A2%91%E2%80%94%E2%80%94%E5%96%84%E5%AD%98%E7%AF%87.mp4')">
            <img :src="`img/1-3huiruishancun.png`" alt="" />
            <div class="intro">辉瑞苏州工厂质量视频——善存篇</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover1-25.png`" alt="">
              <div class="tit">辉瑞苏州工厂质量视频——善存篇</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('《爱的捉迷藏》——Director\'s Cut','https://kangfei123.oss-cn-beijing.aliyuncs.com/%E4%BC%81%E4%B8%9A%E5%AE%A3%E4%BC%A0%E7%89%87/2%E5%BF%97%E9%82%A6%E7%88%B1%E5%AE%B6%E8%A1%8C%E5%8A%A8/%E3%80%8A%E7%88%B1%E7%9A%84%E6%8D%89%E8%BF%B7%E8%97%8F%E3%80%8B%E2%80%94%E2%80%94Director%27s%20Cut.mp4')">
            <img :src="`img/2-1zhibangzhuomicang.png`" alt="" />
            <div class="intro">《爱的捉迷藏》——Director's Cut</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover1-1.png`" alt="">
              <div class="tit">《爱的捉迷藏》——Director's Cut</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('《第一口美味特权》','https://kangfei123.oss-cn-beijing.aliyuncs.com/%E4%BC%81%E4%B8%9A%E5%AE%A3%E4%BC%A0%E7%89%87/2%E5%BF%97%E9%82%A6%E7%88%B1%E5%AE%B6%E8%A1%8C%E5%8A%A8/%E3%80%8A%E7%AC%AC%E4%B8%80%E5%8F%A3%E7%BE%8E%E5%91%B3%E7%89%B9%E6%9D%83%E3%80%8B%E2%80%94%E2%80%94Director%27s%20Cut.mp4')">
            <img :src="`img/2-2zhibangdiyikou.png`" alt="" />
            <div class="intro">《第一口美味特权》</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover1-2.png`" alt="">
              <div class="tit">《第一口美味特权》</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('《我的治愈系衣柜》','https://kangfei123.oss-cn-beijing.aliyuncs.com/%E4%BC%81%E4%B8%9A%E5%AE%A3%E4%BC%A0%E7%89%87/2%E5%BF%97%E9%82%A6%E7%88%B1%E5%AE%B6%E8%A1%8C%E5%8A%A8/%E3%80%8A%E6%88%91%E7%9A%84%E6%B2%BB%E6%84%88%E7%B3%BB%E8%A1%A3%E6%9F%9C%E3%80%8B%E2%80%94%E2%80%94Director%27s%20Cut.mp4')">
            <img :src="`img/2-3zhibangzhiyuxi.png`" alt="" />
            <div class="intro">《我的治愈系衣柜》</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover1-3.png`" alt="">
              <div class="tit">《我的治愈系衣柜》</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('飞依诺杏聆荟微电影','https://kangfei123.oss-cn-beijing.aliyuncs.com/%E4%BC%81%E4%B8%9A%E5%AE%A3%E4%BC%A0%E7%89%87/3%E9%A3%9E%E4%BE%9D%E8%AF%BA%E6%9D%8F%E8%81%86%E8%8D%9F%E5%BE%AE%E7%94%B5%E5%BD%B1.mp4')">
            <img :src="`img/3-vinnoweidianying.png`" alt="" />
            <div class="intro">飞依诺杏聆荟微电影</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover1-4.png`" alt="">
              <div class="tit">飞依诺杏聆荟微电影</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('住逻辑英文版宣传片','https://kangfei123.oss-cn-beijing.aliyuncs.com/%E4%BC%81%E4%B8%9A%E5%AE%A3%E4%BC%A0%E7%89%87/4%E4%BD%8F%E9%80%BB%E8%BE%91%E8%8B%B1%E6%96%87%E7%89%88%E5%AE%A3%E4%BC%A0%E7%89%87.mp4')">
            <img :src="`img/4-zhuluojiyingwenban.png`" alt="" />
            <div class="intro">住逻辑英文版宣传片</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover1-5.png`" alt="">
              <div class="tit">住逻辑英文版宣传片</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('飞依诺《超声英雄》微电影','https://kangfei123.oss-cn-beijing.aliyuncs.com/%E4%BC%81%E4%B8%9A%E5%AE%A3%E4%BC%A0%E7%89%87/5%E9%A3%9E%E4%BE%9D%E8%AF%BA%E3%80%8A%E8%B6%85%E5%A3%B0%E8%8B%B1%E9%9B%84%E3%80%8B%E5%BE%AE%E7%94%B5%E5%BD%B1.mp4')">
            <img :src="`img/5-chaoshengyingxiong.png`" alt="" />
            <div class="intro">飞依诺《超声英雄》微电影</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover1-6.png`" alt="">
              <div class="tit">飞依诺《超声英雄》微电影</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('艾信opening video','https://kangfei123.oss-cn-beijing.aliyuncs.com/%E4%BC%81%E4%B8%9A%E5%AE%A3%E4%BC%A0%E7%89%87/6%E8%89%BE%E4%BF%A1opening%20video.mp4')">
            <img :src="`img/6-aixinopenvideo.png`" alt="" />
            <div class="intro">艾信opening video</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover1-7.png`" alt="">
              <div class="tit">艾信opening video</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('英途康背景opening video','https://kangfei123.oss-cn-beijing.aliyuncs.com/%E4%BC%81%E4%B8%9A%E5%AE%A3%E4%BC%A0%E7%89%87/7%E8%8B%B1%E9%80%94%E5%BA%B7%E8%83%8C%E6%99%AFopening%20video.mp4')">
            <img :src="`img/7-yingtukangopenvideo.png`" alt="" />
            <div class="intro">英途康背景opening video</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover1-8.png`" alt="">
              <div class="tit">英途康背景opening video</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('飞依诺七周年国际版宣传片','https://kangfei123.oss-cn-beijing.aliyuncs.com/%E4%BC%81%E4%B8%9A%E5%AE%A3%E4%BC%A0%E7%89%87/8%E9%A3%9E%E4%BE%9D%E8%AF%BA%E4%B8%83%E5%91%A8%E5%B9%B4%E5%9B%BD%E9%99%85%E7%89%88%E5%AE%A3%E4%BC%A0%E7%89%87.mp4')">
            <img :src="`img/8-vinno7zhounian.png`" alt="" />
            <div class="intro">飞依诺七周年国际版宣传片</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover1-9.png`" alt="">
              <div class="tit">飞依诺七周年国际版宣传片</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('独墅联盟','https://kangfei123.oss-cn-beijing.aliyuncs.com/%E4%BC%81%E4%B8%9A%E5%AE%A3%E4%BC%A0%E7%89%87/9%E7%8B%AC%E5%A2%85%E8%81%94%E7%9B%9Fopening%20video.mp4')">
            <img :src="`img/9-dushulianmeng.png`" alt="" />
            <div class="intro">独墅联盟</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover1-10.png`" alt="">
              <div class="tit">独墅联盟</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('海斯凯尔宣传片','https://kangfei123.oss-cn-beijing.aliyuncs.com/%E4%BC%81%E4%B8%9A%E5%AE%A3%E4%BC%A0%E7%89%87/9%E6%B5%B7%E6%96%AF%E5%87%AF%E5%B0%94%E5%AE%A3%E4%BC%A0%E7%89%87.mp4')">
            <img :src="`img/10-haisikaierxuanchuanpian.png`" alt="" />
            <div class="intro">海斯凯尔宣传片</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover1-11.png`" alt="">
              <div class="tit">海斯凯尔宣传片</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('飞依诺银杏的故事opening video','https://kangfei123.oss-cn-beijing.aliyuncs.com/%E4%BC%81%E4%B8%9A%E5%AE%A3%E4%BC%A0%E7%89%87/10%E9%A3%9E%E4%BE%9D%E8%AF%BA%E9%93%B6%E6%9D%8F%E7%9A%84%E6%95%85%E4%BA%8Bopening%20video.mp4')">
            <img :src="`img/11-yinxingushi.png`" alt="" />
            <div class="intro">飞依诺银杏的故事opening video</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover1-12.png`" alt="">
              <div class="tit">飞依诺银杏的故事opening video</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('微清访谈宣传片','https://kangfei123.oss-cn-beijing.aliyuncs.com/%E4%BC%81%E4%B8%9A%E5%AE%A3%E4%BC%A0%E7%89%87/11%E5%BE%AE%E6%B8%85%E8%AE%BF%E8%B0%88%E5%AE%A3%E4%BC%A0%E7%89%87.mp4')">
            <img :src="`img/12-weiqingfangtan.png`" alt="" />
            <div class="intro">微清访谈宣传片</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover1-13.png`" alt="">
              <div class="tit">微清访谈宣传片</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('艾琳格瑞宣传片 英文版','https://kangfei123.oss-cn-beijing.aliyuncs.com/%E4%BC%81%E4%B8%9A%E5%AE%A3%E4%BC%A0%E7%89%87/12%E8%89%BE%E7%90%B3%E6%A0%BC%E7%91%9E%E5%AE%A3%E4%BC%A0%E7%89%87%20%E8%8B%B1%E6%96%87%E7%89%88.mov')">
            <img :src="`img/13-ailingerui.png`" alt="" />
            <div class="intro">艾琳格瑞宣传片 英文版</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover1-14.png`" alt="">
              <div class="tit">艾琳格瑞宣传片 英文版</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('独墅联盟宣传片','https://kangfei123.oss-cn-beijing.aliyuncs.com/%E4%BC%81%E4%B8%9A%E5%AE%A3%E4%BC%A0%E7%89%87/12%E7%8B%AC%E5%A2%85%E8%81%94%E7%9B%9F%E5%AE%A3%E4%BC%A0%E7%89%87.mp4')">
            <img :src="`img/14-dushulianmengxuanchuanpian.png`" alt="" />
            <div class="intro">独墅联盟宣传片</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover1-15.png`" alt="">
              <div class="tit">独墅联盟宣传片</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('宜洁微电影','https://kangfei123.oss-cn-beijing.aliyuncs.com/%E4%BC%81%E4%B8%9A%E5%AE%A3%E4%BC%A0%E7%89%87/12%E5%AE%9C%E6%B4%81%E5%BE%AE%E7%94%B5%E5%BD%B1.mp4')">
            <img :src="`img/15-yijieweidianying.png`" alt="" />
            <div class="intro">宜洁微电影</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover1-16.png`" alt="">
              <div class="tit">宜洁微电影</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('TaTME影片','https://kangfei123.oss-cn-beijing.aliyuncs.com/%E4%BC%81%E4%B8%9A%E5%AE%A3%E4%BC%A0%E7%89%87/13TaTME%E5%BD%B1%E7%89%87.mp4')">
            <img :src="`img/16-tatmeyingpian.png`" alt="" />
            <div class="intro">TaTME影片</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover1-17.png`" alt="">
              <div class="tit">TaTME影片</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('众享比特宣传片','https://kangfei123.oss-cn-beijing.aliyuncs.com/%E4%BC%81%E4%B8%9A%E5%AE%A3%E4%BC%A0%E7%89%87/14%E4%BC%97%E4%BA%AB%E6%AF%94%E7%89%B9%E5%AE%A3%E4%BC%A0%E7%89%87.mp4')">
            <img :src="`img/17-zhongxiangbitexuanchuanpian.png`" alt="" />
            <div class="intro">众享比特宣传片</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover1-18.png`" alt="">
              <div class="tit">众享比特宣传片</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('合肥数字经济产业试验区宣传片','https://kangfei123.oss-cn-beijing.aliyuncs.com/%E4%BC%81%E4%B8%9A%E5%AE%A3%E4%BC%A0%E7%89%87/16%E5%90%88%E8%82%A5%E6%95%B0%E5%AD%97%E7%BB%8F%E6%B5%8E%E4%BA%A7%E4%B8%9A%E8%AF%95%E9%AA%8C%E5%8C%BA%E5%AE%A3%E4%BC%A0%E7%89%87.mp4')">
            <img :src="`img/19-hefeishuzijingji.png`" alt="" />
            <div class="intro">合肥数字经济产业试验区宣传片</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover1-20.png`" alt="">
              <div class="tit">合肥数字经济产业试验区宣传片</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('网易数字经济宣传片','https://kangfei123.oss-cn-beijing.aliyuncs.com/%E4%BC%81%E4%B8%9A%E5%AE%A3%E4%BC%A0%E7%89%87/17%E7%BD%91%E6%98%93%E6%95%B0%E5%AD%97%E7%BB%8F%E6%B5%8E%E5%AE%A3%E4%BC%A0%E7%89%87.mp4')">
            <img :src="`img/20-wangyishuzijingji.png`" alt="" />
            <div class="intro">网易数字经济宣传片</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover1-21.png`" alt="">
              <div class="tit">网易数字经济宣传片</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('易活生物海外版本宣传片','https://kangfei123.oss-cn-beijing.aliyuncs.com/%E4%BC%81%E4%B8%9A%E5%AE%A3%E4%BC%A0%E7%89%87/18%E6%98%93%E6%B4%BB%E7%94%9F%E7%89%A9%E6%B5%B7%E5%A4%96%E7%89%88%E6%9C%AC%E5%AE%A3%E4%BC%A0%E7%89%87.mp4')">
            <img :src="`img/21-yihuoshengwu.png`" alt="" />
            <div class="intro">易活生物海外版本宣传片</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover1-22.png`" alt="">
              <div class="tit">易活生物海外版本宣传片</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
        </div>
        <div class="tabContent" v-if="cur == 3">
          <div class="pics" @click="showVideo('飞依诺G86产品影片','https://kangfei123.oss-cn-beijing.aliyuncs.com/1%E9%A3%9E%E4%BE%9D%E8%AF%BAG86%E4%BA%A7%E5%93%81%E5%BD%B1%E7%89%87.mp4')">
            <img :src="`img/1-g86.png`" alt="" />
            <div class="intro">飞依诺G86产品影片</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover1.png`" alt="">
              <div class="tit">飞依诺G86产品影片</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('IntoCare电动吻合器产品影片','https://kangfei123.oss-cn-beijing.aliyuncs.com/2%E9%A3%9E%E4%BE%9D%E8%AF%BAM86%E4%B8%BB%E9%A2%98%E5%BD%B1%E7%89%87.mp4')">
            <img :src="`img/2-m86.png`" alt="" />
            <div class="intro">飞依诺M86主题影片</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover2.png`" alt="">
              <div class="tit">飞依诺M86主题影片</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('飞依诺M80产品影片','https://kangfei123.oss-cn-beijing.aliyuncs.com/3%E9%A3%9E%E4%BE%9D%E8%AF%BAM80%E4%BA%A7%E5%93%81%E5%BD%B1%E7%89%87.mp4')">
            <img :src="`img/3-m80.png`" alt="" />
            <div class="intro">飞依诺M80产品影片</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover3.png`" alt="">
              <div class="tit">飞依诺M80产品影片</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('VINNO - G55产品影片','https://kangfei123.oss-cn-beijing.aliyuncs.com/4VINNO%20-%20G55%E4%BA%A7%E5%93%81%E5%BD%B1%E7%89%87.mp4')">
            <img :src="`img/4-g55.png`" alt="" />
            <div class="intro">VINNO - G55产品影片</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover4.png`" alt="">
              <div class="tit">vVINNO - G55产品影片</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('飞依诺Q系列影片','https://kangfei123.oss-cn-beijing.aliyuncs.com/5%E9%A3%9E%E4%BE%9D%E8%AF%BAQ%E7%B3%BB%E5%88%97%E5%BD%B1%E7%89%87.mp4')">
            <img :src="`img/5-q.png`" alt="" />
            <div class="intro">飞依诺Q系列影片</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover5.png`" alt="">
              <div class="tit">飞依诺Q系列影片</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('英途康ICS三维影片','https://kangfei123.oss-cn-beijing.aliyuncs.com/6%E8%8B%B1%E9%80%94%E5%BA%B7ICS%E4%B8%89%E7%BB%B4%E5%BD%B1%E7%89%87.mp4')">
            <img :src="`img/6-ics.png`" alt="" />
            <div class="intro">英途康ICS三维影片</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover6.png`" alt="">
              <div class="tit">英途康ICS三维影片</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('小米金属行李箱产品影片','https://kangfei123.oss-cn-beijing.aliyuncs.com/7%E5%B0%8F%E7%B1%B3%E9%87%91%E5%B1%9E%E8%A1%8C%E6%9D%8E%E7%AE%B1%E4%BA%A7%E5%93%81%E5%BD%B1%E7%89%87.mp4')">
            <img :src="`img/7-mimetal.png`" alt="" />
            <div class="intro">小米金属行李箱产品影片</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover7.png`" alt="">
              <div class="tit">小米金属行李箱产品影片</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('海斯凯尔 - iLivTouch宣传片','https://kangfei123.oss-cn-beijing.aliyuncs.com/8%E6%B5%B7%E6%96%AF%E5%87%AF%E5%B0%94%20-%20iLivTouch%E5%AE%A3%E4%BC%A0%E7%89%87.mp4')">
            <img :src="`img/8-ilivtouch.png`" alt="" />
            <div class="intro">海斯凯尔 - iLivTouch宣传片</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover8.png`" alt="">
              <div class="tit">海斯凯尔 - iLivTouch宣传片</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('宜洁彩色餐具三维影片','https://kangfei123.oss-cn-beijing.aliyuncs.com/9%E5%AE%9C%E6%B4%81%E5%BD%A9%E8%89%B2%E9%A4%90%E5%85%B7%E4%B8%89%E7%BB%B4%E5%BD%B1%E7%89%87.mp4')">
            <img :src="`img/9-yijiecaisecanju.png`" alt="" />
            <div class="intro">宜洁彩色餐具三维影片</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover9.png`" alt="">
              <div class="tit">宜洁彩色餐具三维影片</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('小米PC行李箱产品影片','https://kangfei123.oss-cn-beijing.aliyuncs.com/10%E5%B0%8F%E7%B1%B3PC%E8%A1%8C%E6%9D%8E%E7%AE%B1%E4%BA%A7%E5%93%81%E5%BD%B1%E7%89%87.mp4')">
            <img :src="`img/10-mipc.png`" alt="" />
            <div class="intro">小米PC行李箱产品影片</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover10.png`" alt="">
              <div class="tit">小米PC行李箱产品影片</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('飞依诺产品短片','https://kangfei123.oss-cn-beijing.aliyuncs.com/11%E9%A3%9E%E4%BE%9D%E8%AF%BA%E4%BA%A7%E5%93%81%E7%9F%AD%E7%89%87.mp4')">
            <img :src="`img/11-vinno.png`" alt="" />
            <div class="intro">飞依诺产品短片</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover11.png`" alt="">
              <div class="tit">飞依诺产品短片</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('飞依诺UI影片','https://kangfei123.oss-cn-beijing.aliyuncs.com/12%E9%A3%9E%E4%BE%9D%E8%AF%BAUI%E5%BD%B1%E7%89%87.mp4')">
            <img :src="`img/12-vinnoui.png`" alt="" />
            <div class="intro">飞依诺UI影片</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover12.png`" alt="">
              <div class="tit">飞依诺UI影片</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('宜洁木纹餐具三维影片','https://kangfei123.oss-cn-beijing.aliyuncs.com/12%E5%AE%9C%E6%B4%81%E6%9C%A8%E7%BA%B9%E9%A4%90%E5%85%B7%E4%B8%89%E7%BB%B4%E5%BD%B1%E7%89%87.mp4')">
            <img :src="`img/13-yijiemuwencanju.png`" alt="" />
            <div class="intro">宜洁木纹餐具三维影片</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover13.png`" alt="">
              <div class="tit">宜洁木纹餐具三维影片</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('来也科技智能机器人宣传片','https://kangfei123.oss-cn-beijing.aliyuncs.com/13%E6%9D%A5%E4%B9%9F%E7%A7%91%E6%8A%80%E6%99%BA%E8%83%BD%E6%9C%BA%E5%99%A8%E4%BA%BA%E5%AE%A3%E4%BC%A0%E7%89%87.mp4')">
            <img :src="`img/14-laiyekeji.png`" alt="" />
            <div class="intro">来也科技智能机器人宣传片</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover14.png`" alt="">
              <div class="tit">来也科技智能机器人宣传片</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('宜洁扑克牌产品影片','https://kangfei123.oss-cn-beijing.aliyuncs.com/13%E5%AE%9C%E6%B4%81%E6%89%91%E5%85%8B%E7%89%8C%E4%BA%A7%E5%93%81%E5%BD%B1%E7%89%87.mp4')">
            <img :src="`img/15-yijiepukepai.png`" alt="" />
            <div class="intro">宜洁扑克牌产品影片</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover15.png`" alt="">
              <div class="tit">宜洁扑克牌产品影片</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('爱生气气泡酒','https://kangfei123.oss-cn-beijing.aliyuncs.com/15%E7%88%B1%E7%94%9F%E6%B0%94%E6%B0%94%E6%B3%A1%E9%85%92.mp4')">
            <img :src="`img/17-aishengqi.png`" alt="" />
            <div class="intro">爱生气气泡酒</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover17.png`" alt="">
              <div class="tit">爱生气气泡酒</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('柏兆记产品短视频','https://kangfei123.oss-cn-beijing.aliyuncs.com/16%E6%9F%8F%E5%85%86%E8%AE%B0%E4%BA%A7%E5%93%81%E7%9F%AD%E8%A7%86%E9%A2%91.mp4')">
            <img :src="`img/18-bozhaoji.png`" alt="" />
            <div class="intro">柏兆记产品短视频</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover18.png`" alt="">
              <div class="tit">柏兆记产品短视频</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('宜洁雨伞产品影片','https://kangfei123.oss-cn-beijing.aliyuncs.com/16%E5%AE%9C%E6%B4%81%E9%9B%A8%E4%BC%9E%E4%BA%A7%E5%93%81%E5%BD%B1%E7%89%87.mp4')">
            <img :src="`img/19-yijieyusan.png`" alt="" />
            <div class="intro">宜洁雨伞产品影片</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover19.png`" alt="">
              <div class="tit">宜洁雨伞产品影片</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('老乡鸡云南辣椒影片','https://kangfei123.oss-cn-beijing.aliyuncs.com/17%E8%80%81%E4%B9%A1%E9%B8%A1%E4%BA%91%E5%8D%97%E8%BE%A3%E6%A4%92%E5%BD%B1%E7%89%87.mp4')">
            <img :src="`img/20-laoxiangji.png`" alt="" />
            <div class="intro">老乡鸡云南辣椒影片</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover20.png`" alt="">
              <div class="tit">老乡鸡云南辣椒影片</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('荷乐事饮品宣传片','https://kangfei123.oss-cn-beijing.aliyuncs.com/18%E8%8D%B7%E4%B9%90%E4%BA%8B%E9%A5%AE%E5%93%81%E5%AE%A3%E4%BC%A0%E7%89%87.mp4')">
            <img :src="`img/21-heleshiyinpin.png`" alt="" />
            <div class="intro">荷乐事饮品宣传片</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover21.png`" alt="">
              <div class="tit">荷乐事饮品宣传片</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('飞依诺杏聆荟远程功能视频教学影片','https://kangfei123.oss-cn-beijing.aliyuncs.com/19%E9%A3%9E%E4%BE%9D%E8%AF%BA%E6%9D%8F%E8%81%86%E8%8D%9F%E8%BF%9C%E7%A8%8B%E5%8A%9F%E8%83%BD%E8%A7%86%E9%A2%91%E6%95%99%E5%AD%A6%E5%BD%B1%E7%89%87.mp4')">
            <img :src="`img/22-vinnoxinlinhui.png`" alt="" />
            <div class="intro">飞依诺杏聆荟远程功能视频教学影片</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover22.png`" alt="">
              <div class="tit">飞依诺杏聆荟远程功能视频教学影片</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('英途康电子说明书','https://kangfei123.oss-cn-beijing.aliyuncs.com/20%E8%8B%B1%E9%80%94%E5%BA%B7%E7%94%B5%E5%AD%90%E8%AF%B4%E6%98%8E%E4%B9%A6.mp4')">
            <img :src="`img/23-yingtukang.png`" alt="" />
            <div class="intro">英途康电子说明书</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover23.png`" alt="">
              <div class="tit">英途康电子说明书</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('日式松茸饭影片','https://kangfei123.oss-cn-beijing.aliyuncs.com/21%E6%97%A5%E5%BC%8F%E6%9D%BE%E8%8C%B8%E9%A5%AD%E5%BD%B1%E7%89%87.mp4')">
            <img :src="`img/24-rishisongrongfan.png`" alt="" />
            <div class="intro">日式松茸饭影片</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover24.png`" alt="">
              <div class="tit">日式松茸饭影片</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('英途康ICS组织呈现应用视频1','https://kangfei123.oss-cn-beijing.aliyuncs.com/6%E8%8B%B1%E9%80%94%E5%BA%B7ICS%E4%B8%89%E7%BB%B4%E5%BD%B1%E7%89%87.mp4')">
            <img :src="`img/25-yingtukangics1.png`" alt="" />
            <div class="intro">英途康ICS组织呈现应用视频1</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover25.png`" alt="">
              <div class="tit">英途康ICS组织呈现应用视频1</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('英途康ICS组织呈现应用视频2','')">
            <img :src="`img/26-yingtukangics2.png`" alt="" />
            <div class="intro">英途康ICS组织呈现应用视频2</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover26.png`" alt="">
              <div class="tit">英途康ICS组织呈现应用视频2</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('英途康ICS组织呈现应用视频3','')">
            <img :src="`img/27-yingtukangics3.png`" alt="" />
            <div class="intro">英途康ICS组织呈现应用视频3</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hover27.png`" alt="">
              <div class="tit">英途康ICS组织呈现应用视频3</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
        </div>
        <div class="tabContent" v-if="cur == 4">
          
        </div>
        <div class="tabContent" v-if="cur == 5">
          <div class="pics" @click="showVideo('车友援mg','https://kangfei123.oss-cn-beijing.aliyuncs.com/MG%E5%8A%A8%E7%94%BB/1%E8%BD%A6%E5%8F%8B%E6%8F%B4MG.mp4')">
            <img :src="`img/1-cheyouyuanmg.png`" alt="" />
            <div class="intro">车友援mg</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hovermg1.png`" alt="">
              <div class="tit">车友援mg</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('安徽旅游日logo解读','https://kangfei123.oss-cn-beijing.aliyuncs.com/MG%E5%8A%A8%E7%94%BB/2%E5%AE%89%E5%BE%BD%E6%97%85%E6%B8%B8%E6%97%A5logo%E8%A7%A3%E8%AF%BB.mp4')">
            <img :src="`img/2-anhuilvyouri.png`" alt="" />
            <div class="intro">安徽旅游日logo解读</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hovermg2.png`" alt="">
              <div class="tit">安徽旅游日logo解读</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
          <div class="pics" @click="showVideo('艾琳格瑞','https://kangfei123.oss-cn-beijing.aliyuncs.com/MG%E5%8A%A8%E7%94%BB/3%E8%89%BE%E7%90%B3%E6%A0%BC%E7%91%9E.mp4')">
            <img :src="`img/3-ailingerui.png`" alt="" />
            <div class="intro">艾琳格瑞</div>
            <div class="hoverBg">
              <img class="maskBg" :src="`img/hovermg3.png`" alt="">
              <div class="tit">艾琳格瑞</div>
              <div class="line"></div>
              <div class="infos">
                以魔方为理念，将传统的医疗产品带入一个全新的
                科幻世界，利用机器与魔方的结合，突出产品的功 能点。<br />
                该片采用特效与实景的融合，直观的了解G86的产 品。
              </div>
              <div class="img"><img :src="`img/comp.png`" alt="" /></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :title="title" :visible.sync="dialogVisible" width="80%" center :before-close="closeVideo">
      <video ref="myAudio" class="showVideo" controls autoplay :src="mySrc"></video>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeVideo">取 消</el-button>
        <el-button type="primary" @click="closeVideo"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <a ref="gotoTop" href="#pros"></a>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cur: 1,
      dialogVisible: false,
      title: "",
      pageIndex: 0,
      count: 7,
      mySrc:""
    };
  },
  created() {
    this.cur = !!this.$route.query.cur ? this.$route.query.cur : 1;
  },
  mounted() {
    this.$refs.gotoTop.click()
  },
  methods: {
    showVideo(title, src) {
      this.title = title;
      this.audioPause();
      this.dialogVisible = true;
      this.mySrc = src;
    },
    closeVideo(){
      this.audioPause();
      this.dialogVisible = false
    },
    audioPause() {
      //暂停并且设置当前播放时间为0
      let audio = this.$refs.myAudio;
      if (!!audio && (!audio.paused || audio.currentTime !== 0)) {
        audio.pause();
        audio.currentTime = 0;
      }
    },
    nextPage() {
      if (this.pageIndex < this.count - 1) {
        this.pageIndex++;
      } else {
        this.pageIndex = 0;
      }
    },
  },
};
</script>

<style lang="stylus" scoped  rel="stylesheet/stylus">
.wrap {
  background-color: #F1F1F1;
}

.showVideo {
  width: 100%;
}

.project {
  width: 1240px;
  margin: 0 auto;

  .tabs {
    padding-top: 20px;

    .tabNav {
      display: flex;
      align-items: center;

      .item {
        height: 33px;
        border: 2px solid #333333;
        border-radius: 17px;
        padding: 0 20px;
        line-height: 33px;
        margin-right: 29px;
        cursor: pointer;

        &.cur {
          background-color: #333333;
          color: #ffffff;
        }
      }
    }

    .tabContent {
      padding: 125px 0 110px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      position: relative;

      .pics {
        width: 341px;
        height: 320px;
        padding-bottom: 39px;
        cursor: pointer;
        position: relative;

        img {
          width: 341px;
          height: 260px;
        }

        .intro {
          padding-top: 15px;
          font-size: 20px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #333333;
        }

        .hoverBg {
          display: none;
          position: absolute;
          top: 0;
          left: -60px;
          width: 462px;
          height: 358px;
          color: #ffffff;
          box-sizing: border-box;
          padding: 47px 38px 23px;
          
          .maskBg{
            width: 462px;
            height: 358px;
            position: absolute;
            top: 0;
            left: 0;
          }

          .tit {
            font-size: 24px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #F2F2F2;
            line-height: 43px;
            position: absolute;
          }

          .line {
            width: 41px;
            height: 2px;
            border-top: 2px solid #FAFAFA;
            margin: 10px 0 15px 0;
            position: absolute;
            top: 90px;
          }

          .infos {
            font-size: 17px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #F2F2F2;
            line-height: 33px;
            position: absolute;
            top: 135px;
          }

          .img {
            width: 48px;
            height: 48px;
            margin: 22px auto 0;
            position: absolute;
            bottom: 20px;
            left 50%
            margin-left: -20px;

            img {
              width: 48px;
              height: 48px;
            }
          }
        }

        &:hover {
          .hoverBg {
            display: block;
          }
        }
      }

      &:after {
        width: 341px;
        padding-bottom: 50px;
        content: '';
      }

      .pages {
        height: 60px;
        line-height: 60px;
        display: none;
        position: absolute;
        bottom: 45px;
        left: 0;
        width: 100%;

        .page {
          width: 240px;
          height: 60px;
          background: #FFFFFF;
          border-radius: 30px;
          padding: 0 36px;

          .pageNo {
            display: inline-block;
            width: 29px;
            height: 29px;
            text-align: center;
            line-height: 29px;
            font-size: 15px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #555555;
            margin-right: 5px;
            cursor: pointer;

            &.check {
              display: inline-block;
              width: 29px;
              height: 29px;
              background: #16618A;
              border-radius: 50%;
              text-align: center;
              line-height: 29px;
              color: #FFFFFF;
            }
          }
        }

        .nextPage {
          position: absolute;
          right: 0;
          top: 0;
          width: 60px;
          height: 60px;
          cursor: pointer;
        }
      }

      &.spec {
        .pages {
          display: block;
        }
      }
    }
  }
}
</style>